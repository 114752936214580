@use '@angular/material' as mat;

@include mat.core();

$crm-palette: (
  50: #e4f2ff,
  100: #bddfff,
  200: #94ccff,
  300: #69b7ff,
  400: #4ba7ff,
  500: #3399fe,
  600: #328aef,
  700: #2e78dc,
  800: #2b66c9,
  900: #2648aa,
  contrast: (
    50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$crm-frontend-primary: mat.define-palette($crm-palette);
$crm-frontend-accent: mat.define-palette($crm-palette);

$crm-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $crm-frontend-primary,
      accent: $crm-frontend-accent,
    ),
  )
);

@include mat.all-component-themes($crm-frontend-theme);

@import './app/styles/base.scss';

@font-face {
  font-family: 'Poppins Light';
  src: url('./assets/fonts/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'Poppins Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Ubuntu Light';
  src: url('./assets/fonts/Ubuntu-Light.ttf');
}

@font-face {
  font-family: 'Ubuntu Medium';
  src: url('./assets/fonts/Ubuntu-Medium.ttf');
}

@font-face {
  font-family: 'Ubuntu Bold';
  src: url('./assets/fonts/Ubuntu-Bold.ttf');
}

* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  border: none;
  font-family: $fontFamilyPoppinsRegular;
}

button {
  padding: 0;
  outline: none;
}

a {
  text-decoration: none;
  color: inherit;
}

hr {
  background-color: $greyLightColor;
  margin: 0;
  height: 1px;
  border: none;
}

.separator {
  width: 1px;
  height: 100%;
  background-color: $greyLightColor;
}

.clickable {
  cursor: pointer;
}

.bg-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $blackColor;
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s linear;
  transform: scale(0.9, 0.6);
  opacity: 0;
  border-radius: $largeBorderRadius;
}

.page-title {
  font-family: $fontFamilyPoppinsBold;
  font-size: $xxLargeFontSize;
}

.page-container {
  position: relative;

  .sticky {
    position: sticky;
    top: 0;
    left: 0;
    background-color: $greyBackgroundColor;
    z-index: 101;
  }

  .head-container {
    display: grid;
    grid-template-columns: 2fr 200px;
    justify-content: space-between;
    grid-row-gap: $mediumMargin;
    margin-right: $xxLargeMargin;
    padding: $largeMargin 0 0 $xxLargeMargin;

    .filters-container {
      display: flex;
      column-gap: $xSmallMargin;
      row-gap: $xSmallMargin;
      flex-wrap: wrap;
    }

    app-search-bar,
    app-button {
      justify-self: end;
      align-self: center;
    }
  }

  hr {
    margin: $mediumMargin 0 $mediumMargin $xxLargeMargin;
  }

  .cards-container {
    display: flex;
    flex-direction: column;
    row-gap: $xSmallMargin;
    margin: 0 $xxLargeMargin $xxLargeMargin;

    .info-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: $xSmallFontSize;
      color: $greyDarkColor;

      .name {
        font-family: $fontFamilyPoppinsBold;
        font-size: $largeFontSize;
        margin-bottom: $xxxSmallMargin;
        color: $blackColor;
      }
    }

    .label {
      display: flex;
      color: $whiteColor;
      padding: $xxSmallMargin $mediumMargin;
      border-top-left-radius: $mediumBorderRadius;
      border-bottom-left-radius: $mediumBorderRadius;
      font-size: $xSmallFontSize;
      font-family: $fontFamilyPoppinsLight;
      min-width: 326px;

      &.green {
        background-color: rgba($greenColor, 0.8);
      }

      &.cyan {
        background-color: rgba($cyanColor, 0.8);
      }

      &.orange {
        background-color: rgba($orangeColor, 0.8);
      }

      &.red {
        background-color: rgba($redColor, 0.8);
      }

      .status {
        text-transform: uppercase;
        font-family: $fontFamilyPoppinsBold;
      }

      .separator {
        margin: 2px $smallMargin;
        background-color: $white30Color;
        height: calc(100% - 2 * 2px);
      }

      .date {
        font-family: $fontFamilyPoppinsMedium;
        margin-left: $xSmallMargin;
      }
    }

    &.not-found {
      align-items: center;
      justify-content: center;
      $filterButtonHeight: 36px;
      $headerHeight: $largeMargin + $titleHeight + $mediumMargin + $filterButtonHeight + 1px + 2 * $mediumMargin;
      height: calc(100vh - $headerHeight - $largeMargin);
    }
  }
}

.not-found {
  font-family: $fontFamilyPoppinsBold;
  font-size: $xxLargeFontSize;
  color: $greyMediumColor;
}

mat-datepicker-toggle {
  .mat-mdc-icon-button {
    &.mat-mdc-button-base {
      padding: 0;
      --mdc-icon-button-state-layer-size: 15px;
    }

    .mat-mdc-button-persistent-ripple::before {
      display: none;
    }

    .mat-mdc-button-touch-target {
      width: $defaultHeight;
      height: $defaultHeight;
    }
  }

  .mat-ripple.mat-mdc-button-ripple {
    display: none;
  }

  &:hover {
    .bg-effect {
      transform: scale(1);
      opacity: 0.1;
    }
  }
}

ol {
  margin: 0;
  padding: 0;
}

mat-date-range-input {
  .mat-date-range-input-container {
    height: 100%;
    padding-top: 2px;
    margin-left: calc($mediumMargin + $smallMargin + $smallMargin); // Left margin + calendar width + right margin
    cursor: pointer;

    input {
      user-select: none;
      pointer-events: none;
    }
  }

  .mat-date-range-input-wrapper {
    height: 100%;
  }
}

mat-datepicker-content {
  font-family: $fontFamilyPoppinsMedium;

  .mat-calendar-period-button {
    font-family: $fontFamilyPoppinsMedium;
  }

  .mat-calendar-table-header {
    font-family: $fontFamilyPoppinsRegular;
    font-style: italic;
  }
}

.modal-form {
  .mat-mdc-dialog-container {
    min-width: 300px !important;
    max-width: 650px !important;
    width: 50vw !important;
  }
}

.new-modal-form {
  .mat-mdc-dialog-container {
    min-width: fit-content !important;
    max-width: fit-content !important;
    width: fit-content !important;
    height: fit-content !important;
  }
}

.success-alert {
  .mat-mdc-dialog-container {
    min-width: 300px !important;
    max-width: 520px !important;
    width: 50vw !important;
  }
}

.mdc-dialog__surface {
  border-radius: $largeBorderRadius !important;
}

/* LoadingDirective overlay styles */
.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 100;

  &.loading-background {
    background-color: rgba($whiteColor, 0.6);
  }
}

// kanban
.cdk-drag:not(.cdk-drag-preview) {
  transition: transform 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0.3;
}
