// COLORS
$blueDefaultColor: #3399fe;
$blueDarkColor: #112233;
$blueBackgroundLightColor: #0d5dad;
$blueBackgroundDarkColor: #112233;
$whiteColor: #fff;
$white30Color: rgba($whiteColor, 0.3);
$white50Color: rgba($whiteColor, 0.5);
$white70Color: rgba($whiteColor, 0.7);
$greyBackgroundColor: #f0f0f0;
$greyLightColor: #e0e0e0;
$greyMediumColor: #858585;
$greyDarkColor: #5f5f5f;
$blackColor: #000;
$black20Color: rgba($blackColor, 0.2);
$greenColor: #00b976;
$orangeColor: #fe6600;
$redColor: #e30000;
$purpleColor: #8800e3;
$cyanColor: #00c5e3;
$yellowColor: #ffa000;

// SHADOWS
$boxShadow: 0 5px 8px 0 $black20Color;

// GRADIENTS
$toRightBackgroundGradient: linear-gradient(118deg, $blueBackgroundDarkColor, $blueBackgroundLightColor);
$toLeftBackgroundGradient: linear-gradient(298deg, $blueBackgroundDarkColor, $blueBackgroundLightColor);

// FONTS
$xSmallFontSize: 12px;
$smallFontSize: 13px;
$mediumFontSize: 15px;
$largeFontSize: 20px;
$xLargeFontSize: 25px;
$xxLargeFontSize: 30px;
$xxxLargeFontSize: 40px;

$lightFontWeight: 300;
$normalFontWeight: 400;
$mediumFontWeight: 500;
$semiBoldFontWeight: 600;
$boldFontWeight: 700;

$fontFamilyPoppinsLight: 'Poppins Light', sans-serif;
$fontFamilyPoppinsRegular: 'Poppins Regular', sans-serif;
$fontFamilyPoppinsMedium: 'Poppins Medium', sans-serif;
$fontFamilyPoppinsSemiBold: 'Poppins SemiBold', sans-serif;
$fontFamilyPoppinsBold: 'Poppins Bold', sans-serif;

$fontFamilyUbuntuLight: 'Ubuntu Light', sans-serif;
$fontFamilyUbuntuMedium: 'Ubuntu Medium', sans-serif;
$fontFamilyUbuntuBold: 'Ubuntu Bold', sans-serif;

// MARGINS
$xxxSmallMargin: 5px;
$xxSmallMargin: 8px;
$xSmallMargin: 10px;
$smallMargin: 15px;
$mediumMargin: 20px;
$largeMargin: 30px;
$xLargeMargin: 40px;
$xxLargeMargin: 50px;

// OTHERS
$smallBorderRadius: 3px;
$mediumBorderRadius: 5px;
$largeBorderRadius: 10px;

$defaultHeight: 35px;
$textareaHeight: 100px;
$checkboxHeight: 15px;
$mediumLabelHeight: 27px;
$largeLabelHeight: 33px;
$taskStatusDropdownHeight: 27px;
$tabsHeight: 42px;
$titleHeight: 46px;

$sidebarWidth: 300px;

$dividerWidth: 1px;
$authContentWidth: 350px;
$authContentRowGap: 130px;

$accountIconSmallSize: 30px;
$accountIconMediumSize: 70px;
$accountIconLargeSize: 230px;
